<script>
import {AgGridVue} from "ag-grid-vue";
import DateCell from "../grid/generic/DateCell";
import SimplePriceCell from "../grid/generic/SimplePriceCell";
import PriceArticleDisplayCell from "../grid/PriceArticleDisplayCell";
import PricesService from "../../services/prices.service";
import {mapState} from "vuex";
import SelectTitleHeader from "../grid/SelectTitleHeader.vue";

export default {
  name: "PricesGrid",
  data() {
    return {
      rowCount: 0,
      gridApi: null,
      columnApi: null,
      rowBuffer: null,
      rowModelType: null,
      cacheBlockSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      debounceLength: null,
      maxBlocksInCache: null,
      showActions: false,
      isLoading: true,
      rowSelection: "",
      selectedArticle: false,
      showArticleSearch: false,
      searchTerm: '',
      showResults: false,
      datasource: null,
      defaultColDef: {
        floatingFilter: true,
      },
      columnDefs: [
        {
          field: "title",
          resizable: true,
          headerName: "Title",
          filter: true,
          filterParams: {
            buttons: [
              'apply'
            ]
          },
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'PriceArticleDisplayCell',
          width: 230,
          checkboxSelection: true,
          headerComponent: 'SelectTitleHeader',
        },
        {
          field: "supplier.name",
          resizable: false,
          headerName: "Supplier",
          filter: true,
          filterParams: {
            filterOptions: ['contains'],
            buttons: [
              'apply'
            ],
            suppressAndOrCondition: true
          },
          cellStyle: {'text-align': 'left'},
          width: 80
        },
        {
          field: "article_id",
          resizable: false,
          headerName: "Latina ID",
          cellStyle: {'text-align': 'left'},
          cellRenderer: function (params) {
            if (params.value) {
              return params.data.article.latina_article_id;
            }

            return '';
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => cellValue !== null,
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          },
          width: 80
        },
        {
          field: "supplier_article.supplier_identifier",
          resizable: false,
          headerName: "Supplier ID",
          filter: 'agTextColumnFilter',
          cellStyle: {'text-align': 'left'},
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              },
              {
                displayKey: 'notBlanks',
                displayName: 'Not Blank',
                predicate: (_, cellValue) => cellValue !== null,
                numberOfInputs: 0,
              },
              {
                displayKey: 'blanks',
                displayName: 'Blank',
                predicate: (_, cellValue) => cellValue === null,
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          },
          width: 100
        },
        {
          field: "supplier_article.ean_article",
          resizable: true,
          headerName: "EAN",
          filter: 'agTextColumnFilter',
          cellStyle: {'text-align': 'left'},
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              {
                displayKey: 'contains',
                displayName: 'Contains',
                predicate: (_, cellValue) => cellValue !== _,
                numberOfInputs: 1,
              }
            ],
            suppressAndOrCondition: true,
          },
          width: 100
        },
        {
          field: "supplier_article.is_gift_box",
          resizable: false,
          headerName: "GB/NGB",
          cellStyle: {'text-align': 'left'},
          cellRenderer: function (params) {
            if (params.data) {
              let isGiftBox = false;

              if (params.data.supplier_article) {
                if (params.data.supplier_article.is_gift_box === 1) {
                  isGiftBox = true;
                }
              }

              if (params.data.article) {
                if (params.data.article.is_gift_box === 1) {
                  isGiftBox = true;
                }
              }

              return isGiftBox ? 'GB' : 'NGB';
            }

            return '';
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              'empty',
              {
                displayKey: 'equalsGb',
                displayName: 'GB',
                predicate: (_, cellValue) => cellValue === 'GB',
                numberOfInputs: 0,
              },
              {
                displayKey: 'equalsNgb',
                displayName: 'NGB',
                predicate: (_, cellValue) => cellValue === 'NGB',
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          },
          width: 80
        },
        {
          field: "is_refillable",
          resizable: false,
          headerName: "RF/NRF",
          cellStyle: {'text-align': 'left'},
          cellRenderer: function (params) {
            if (params.data) {
              let isRefillable = true;

              if (params.data.is_refillable === 0) {
                isRefillable = false;
              }

              if (params.data.article) {
                if (params.data.article.is_refillable === 'NRF') {
                  isRefillable = false;
                }
              }
              return isRefillable ? 'RF' : 'NRF';
            }

            return '';
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              'empty',
              {
                displayKey: 'equalsRF',
                displayName: 'RF',
                predicate: (_, cellValue) => cellValue === 'RF',
                numberOfInputs: 0,
              },
              {
                displayKey: 'equalsNRF',
                displayName: 'NRF',
                predicate: (_, cellValue) => cellValue === 'NRF',
                numberOfInputs: 0,
              },
            ],
            suppressAndOrCondition: true,
          },
          width: 70
        },
        {
          field: "price_date",
          resizable: false,
          headerName: "Date",
          filter: 'agDateColumnFilter',

          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            if (params.value) {
              return this.getReadableDifference(new Date(params.value).getTime());
            }
          },
          width: 70
        },
        {
          field: "bottles_per_case",
          resizable: false,
          headerName: "Btls/Case",
          cellStyle: {'text-align': 'center'},
          width: 60,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              'equals',
              'greaterThan',
              'lessThan'
            ],
            suppressAndOrCondition: true
          }
        },
        {
          field: "inner_content_count",
          resizable: false,
          headerName: "Inner Content",
          cellStyle: {'text-align': 'center'},
          cellRenderer: (params) => {
            let valueString = '';
            let unitStringAbbreviation = '';
            let unitString = '';

            if (params.data) {
              unitString = params.data.inner_content_unit;
            }

            switch (unitString) {
              case 'liter':
                unitStringAbbreviation = 'l.';
                break;

              case 'deciliter':
                unitStringAbbreviation = 'dl.';
                break;

              case 'centiliter':
                unitStringAbbreviation = 'cl.';
                break;
            }

            if (params.value) {
              valueString = new Number(params.value);
              return valueString.toFixed(2) + ' ' + unitStringAbbreviation;
            }

            return valueString;
          },
          width: 60,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              'contains',
              'equals',
              'greaterThan',
              'lessThan'
            ],
            suppressAndOrCondition: true
          }
        },
        {
          field: "alcohol_percentage",
          resizable: false,
          headerName: "Alc. %",
          cellStyle: {'text-align': 'left'},
          cellRenderer: (params) => {
            let valueString = '';

            if (params.data) {

              if (params.data.supplier_article) {

                valueString = new Number(params.data.supplier_article.alcohol_percentage);
                valueString = valueString.toFixed(2);
              }

              if (params.data.article) {

                valueString = new Number(params.data.article.alcohol_percentage);
                valueString = valueString.toFixed(2);
              }
            }

            return valueString;
          },
          width: 80,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: [
              'apply'
            ],
            filterOptions: [
              'equals',
              'greaterThan',
              'lessThan'
            ],
            suppressAndOrCondition: true
          }
        },
        {
          field: "unit_price",
          resizable: false,
          headerName: "Unit Price",
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'SimplePriceCell',
          width: 80
        },
        {
          field: "base_currency_package_price",
          resizable: false,
          headerName: "Case Price",
          cellStyle: {'text-align': 'left'},
          cellRenderer: 'SimplePriceCell',
          width: 80
        },
      ],
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, DateCell, SimplePriceCell, PriceArticleDisplayCell, SelectTitleHeader
  },
  created() {
    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.cacheBlockSize = 250;
    this.cacheOverflowSize = 1;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 10000;
    this.rowSelection = "multiple";
    this.maxBlocksInCache = 25;
    this.debounceLength = 1; //ms
    this.isLoading = true;

  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    async fetchPriceCount(params) {
      let data = {};
      await PricesService.fetchPriceCount(params).then((response) => {
        data = response
      });

      return data;
    },
    async fetchPrices(params) {

      this.isLoading = true;

      let data = {};
      await PricesService.fetchPrices(params).then((response) => {
        data = response;
      });

      return data;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.sizeToFit();

      const dataSource = {
        rowCount: undefined,
        getRows: (params) => {
          this.fetchPrices(params).then((data) => {
            params.successCallback(data.data, data.rowCount);

            if (this.shouldSelectAll) {
              this.gridApi.forEachNode(function (node) {
                node.setSelected(true)
              });
            }

            this.isLoading = false;

            this.fetchPriceCount(params).then((resp) => {
              this.rowCount = resp.rowCount;
              this.gridApi.setRowCount(resp.rowCount);
            });
          }, () => {
          });
        }
      }

      this.gridApi.setDatasource(dataSource);

    },
    onSelectionChange() {
      const selectedRows = this.gridApi.getSelectedRows();

      if (selectedRows.length > 0) {
        this.showActions = true;
      } else {
        this.showActions = false;
      }
    },
    selectArticle(article) {
      this.selectedArticle = article;
      this.searchTerm = article.latina_article_id + ' - ' + article.title;
      this.$store.dispatch('newPriceList/clearArticleSearch');
    },
    performSearch() {
      this.$store.dispatch('newPriceList/searchArticles', this.searchTerm)
      this.showResults = true;
    },

    deselectAll() {
      this.gridApi.refreshInfiniteCache();
      this.gridApi.deselectAll();
      this.showResults = false;
      this.selectedArticle = false;
    },
    async performAssignment() {

      const prices = this.gridApi.getSelectedRows().map((item) => {
        return {
          id: item.id
        };
      });

      this.isLoading = true;

      await this.$store.dispatch('prices/reassignMultiplePrices', {
        prices: prices,
        article: this.selectedArticle.id
      });

      await this.$store.dispatch('prices/deselectAll')

      this.gridApi.refreshInfiniteCache();
      this.selectedArticle = false;
      this.searchTerm = '';

    },

    async deleteSelectedPrices() {
      this.isLoading = true;

      const prices = this.gridApi.getSelectedRows().map((item) => {
        return {
          id: item.id
        };
      });

      await this.$store.dispatch('prices/deleteMultiplePrices', {
        prices: prices
      });

      this.gridApi.refreshInfiniteCache();
      this.gridApi.deselectAll();
      this.selectedArticle = false;
      this.searchTerm = '';

    },

    async deAssignSelectedPrices() {
      this.isLoading = true;

      const prices = this.gridApi.getSelectedRows().map((item) => {
        return {
          id: item.id
        };
      });

      await this.$store.dispatch('prices/deAssignMultiplePrices', {
        prices: prices
      });

      this.gridApi.refreshInfiniteCache();
      this.gridApi.deselectAll();
      this.selectedArticle = false;
      this.searchTerm = '';

    }

  },
  computed: mapState({
    searchResults: (state) => state.newPriceList.articleResults,
    isFetching: (state) => state.newPriceList.isFetching,
    shouldSelectAll: (state) => state.prices.selectAll,
  }),

  watch: {
    shouldSelectAll: function (newValue) {

      if (newValue) {

        if (this.rowCount > 250) {
          this.$toast( 'Selection of all prices is limited to the first 250 records. ')
        }

        this.gridApi.forEachNode(function (node) {
          node.setSelected(true)
        });
      } else {
        this.deselectAll()
      }
    }
  },


};
</script>

<template>
  <b-overlay :show="this.isLoading" rounded="sm">
    <div class="actionsPanel alert text-right alert-info">
      <div class="deletePrices" v-if="showActions">
        <b-button variant="danger" @click="deleteSelectedPrices" size="sm">Delete Prices</b-button>
        <b-button variant="warning" @click="deAssignSelectedPrices" size="sm">De-assign Prices</b-button>
        <b-button variant="secondary" @click="deselectAll" size="sm">Deselect All</b-button>
      </div>
      <div class="reAssignPrices" v-if="showActions">
        <div class="articleSearch">
          <span>Re-assign: </span>
          <b-form-input id="articleSearchSearch" placeholder="Search for Articles" ref="searchInput" size="sm"
                        v-on:keyup.enter="performSearch"
                        v-model="searchTerm"></b-form-input>
          <b-button variant="secondary" @click="performSearch" size="sm">Search</b-button>
        </div>
        <div class="articleResults__results" v-if="showResults">
          <div class="articleResults__result" v-for="result in searchResults" :key="result.id"
               v-on:click="selectArticle(result)">
            {{ result.latina_article_id }} - {{ result.title }} - {{ result.content_count }} /
            {{ result.inner_content_count }} / {{ result.alcohol_percentage }}%
          </div>
        </div>
        <b-button class="articleAssignBtn" variant="primary" size="sm" v-if="selectedArticle"
                  @click="performAssignment">Assign To Article
        </b-button>
      </div>
      <div class="pricesSummary">
        {{ parseInt(this.rowCount).toLocaleString() }} Price records
      </div>

    </div>
    <ag-grid-vue style=" width: 100%; height: 78vh;"
                 class="ag-theme-alpine"
                 @grid-ready="onGridReady"
                 @selection-changed="onSelectionChange"
                 :columnDefs="columnDefs"
                 :rowBuffer="rowBuffer"
                 :rowModelType="rowModelType"
                 :rowSelection="rowSelection"
                 :defaultColDef="defaultColDef"
                 :blockLoadDebounceMillis="debounceLength"
                 :cacheBlockSize="cacheBlockSize"
                 :cacheOverflowSize="cacheOverflowSize"
                 :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
                 :infiniteInitialRowCount="infiniteInitialRowCount"
                 :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>
  </b-overlay>
</template>

<style lang="scss">

</style>