<script>
import {mapState} from "vuex";

export default {
  name: 'SelectTitleHeader',
  methods: {
    toggleCheck() {
      if (! this.isChecked) {
        this.$store.dispatch('prices/selectAll');
      } else {
        this.$store.dispatch('prices/deselectAll');
      }
    }
  },
  computed: {
    ...mapState({
        isChecked: state => state.prices.selectAll
    })
  },

}
</script>

<template>
  <div class="d-flex flex-row align-items-center flex-nowrap">
    <b-form-checkbox
        id="header-check-all"
        v-model="isChecked"
        name="header-check-all-1"
        value="true"
        @change="toggleCheck"
        unchecked-value="false"
        class="ag-checkbox"
    >

    </b-form-checkbox>
    <span class="ag-header-cell-text mx-3">Price title</span>
  </div>
</template>