<script>

export default {
  name: 'SimplePriceCell',
  data: function () {
    return {
      price: false,
      currency: '',
    };
  },
  beforeMount() {
    if (! this.params.value) {
      return false;
    }

    this.price = parseFloat(this.params.value);
    this.currency = this.params.data.currency.code;
  }
}
</script>

<template>
  <money-format :value="price" v-if="price"
                :currency-code="currency"
  >
  </money-format>
</template>