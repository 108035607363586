<template>
  <b-modal id="export-prices-modal" size="md" title="Export Sale Prices" v-model="showModal">
    <b-overlay :show="loading" rounded="sm">
      <form>
        <p>This will export the current Catalog as an Excel price list. You will receive the list by email once it's ready.
        Please click "Export" to start the export.
        </p>
        <b-alert variant="danger" :show="this.errors.length > 0">
          <ul>
            <li v-for="error in this.errors" :key="error">{{ error }}</li>
          </ul>
        </b-alert>
      </form>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="success" @click="startExport">
        Export
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: "ExportSalePricesModal",
  data() {
    return {
      file: null,
      errors: [],
      loading: false,
    }
  },
  components: {},
  computed: {
    showModal: {
      get() {
        return this.$store.state.articles.modals.isPricesExportModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openSalePriceExportModal');
        } else {
          this.$store.dispatch('articles/closeSalePriceExportModal');
        }
      }
    }
  },

  methods: {

    startExport() {
      this.loading = true;
      this.$store.dispatch('articles/exportSalePrices').then(() => {
        this.$store.dispatch('articles/closeSalePriceExportModal');
        this.loading = false;
        this.$toast( 'Export is being prepared. Please check your email for a file to download.');
      })
    },

    reset() {
      this.loading = false;
      this.$store.dispatch('articles/closeAddModal');
    },
  }

}
</script>

