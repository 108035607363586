<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Price Records</h4>

        </div>
      </div>
      <div class="col-12">
        <PricesGrid />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import PricesGrid from "../components/prices/PricesGrid";

export default {
  name: 'Prices',
  components: {
    Layout, PricesGrid
  },
  methods: {

  },
  data() {
    return {
    }
  }
}
</script>
